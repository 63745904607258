import React, { useState } from 'react';
import css from './StarRating.css';

const StarRating = ({ rating, onRatingChange }) => {
  const [hover, setHover] = useState(null);

  return (
    <div className={css.star}>
      {[...Array(5)].map((_, index) => {
        const starIndex = index + 1;
        return (
          <span
            key={starIndex}
            className={`star ${starIndex <= (hover || rating) ? 'filled' : ''}`}
            onClick={() => onRatingChange(starIndex)}
            onMouseEnter={() => setHover(starIndex)}
            onMouseLeave={() => setHover(null)}
          >
            ★
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
