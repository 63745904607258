import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews, Button } from '../../components';

import css from './ListingPage.module.css';
import StarRating from './StarRating';
import ReviewTextBox from './ReviewTextBox';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, listingId, user ,author,handleRatingSubmit, fetchOwnListings,listingsCount} = props;
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const handleReviewChange = (newReview) => {
    setReview(newReview);
  };
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (rating) {
      const reviewDetails = {
        reviewRating: rating,
        reviewContent: review,
        listingId: listingId?.uuid,
      }
      handleRatingSubmit(reviewDetails).then(res => {
        setRating(0),
          setReview('')
      }
      );
    }
  };

  return (
    <section className={css.sectionReviews}>
      {user!=author && listingsCount==0?
        <div>
          <h4>Leave a Review</h4>
          <StarRating rating={rating} onRatingChange={handleRatingChange} />
          <br></br>
          <ReviewTextBox review={review} onReviewChange={handleReviewChange} />
          <br></br>
          <div className={css.reviewButton}><Button type="submit" disabled={(rating==0) || (review=='')} onClick={(e) => { handleSubmit(e) }} >Submit</Button></div>
        </div>:null
     }
      <br></br>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}     
      <br></br>
      <Reviews reviews={reviews} />
    </section>
  );
};

export default SectionReviews;
