import React, { useState } from 'react';

const ReviewTextBox = ({ review, onReviewChange }) => {
  return (
    <div className="review-textbox">
      <textarea
        value={review}
        onChange={(e) => onReviewChange(e.target.value)}
        placeholder="Write your review here..."
      />
    </div>
  );
};

export default ReviewTextBox;
